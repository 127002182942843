.newsletter--narrow .newsletter__wrapper,
.newsletter:not(.newsletter--narrow) .newsletter__wrapper.color-background-1 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.newsletter__wrapper:not(.color-background-1) {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.newsletter__wrapper {
  padding-right: 4rem;
  padding-left: 4rem;
}

@media screen and (min-width: 750px) {
  .newsletter__wrapper {
    padding-right: 9rem;
    padding-left: 9rem;
  }
}

.newsletter__wrapper > * {
  margin-top: 0;
  margin-bottom: 0;
}

.newsletter__wrapper > * + * {
  margin-top: 2rem;
}

.newsletter__wrapper > * + .newsletter-form {
  margin-top: 3rem;
}

.newsletter__subheading {
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
}

.newsletter__wrapper .newsletter-form__field-wrapper {
  max-width: 36rem;
}

.newsletter-form__field-wrapper .newsletter-form__message {
  margin-top: 1.5rem;
}

.newsletter__button {
  margin-top: 3rem;
  width: fit-content;
}

@media screen and (min-width: 750px) {
  .newsletter__button {
    flex-shrink: 0;
    margin: 0 0 0 1rem;
  }
}
